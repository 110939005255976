'use client';

import { Box, Typography, useMediaQuery } from '@mui/material';
import { applyFont, copernicus, polaris } from '@chat-dit/ui-util-fonts';
import { TeamsSignIn } from './teams-sign-in';
import { CypressSignIn } from './cypress-sign-in';
import { NorthwellSignIn } from './northwell-sign-in';
import { DevSignIn } from './dev-sign-in';
import { useSetLoginSourceCookie, useSetTimezoneCookie } from '../hooks';
import Image from 'next/image';
import { useEffect, useState } from 'react';
import { app } from '@microsoft/teams-js';
import { useSearchParams } from 'next/navigation';
import { TokenSignIn } from './token-sign-in';

const isCypress = typeof window !== 'undefined' && 'Cypress' in window;
const isDev = process.env.NODE_ENV === 'development';

export function SignIn() {
  const mobile = useMediaQuery('(max-width: 600px)');
  const [inTeams, setInTeams] = useState<boolean | null>(null);
  const determiningTeams = inTeams === null;
  const searchParams = useSearchParams();
  const setLoginSourceCookie = useSetLoginSourceCookie();

  useSetTimezoneCookie();

  useEffect(() => {
    if (isCypress) {
      setLoginSourceCookie('cypress');
      setInTeams(false);
      return;
    }
    app
      .initialize()
      .then(() => {
        // setting this cookie does not work in teams right now, we work around
        // it in auth options which is the only place that uses the cookie
        setLoginSourceCookie('teams');
        setInTeams(true);
      })
      .catch(() => {
        setInTeams(false);
        setLoginSourceCookie(getSourceParam() ?? '');
      });
  }, []);

  function getSignInForm() {
    if (inTeams) {
      return <TeamsSignIn />;
    }
    if (searchParams.has('token')) {
      return <TokenSignIn />;
    }
    return <NorthwellSignIn />;
  }
  return (
    <Box
      height="100%"
      width="100%"
      sx={{ backgroundColor: '#f7faff' }}
      margin={0}
    >
      <Box
        display="flex"
        mb="30vh"
        p={1}
        justifyContent={mobile ? 'space-between' : undefined}
      >
        <Image
          src="/NW_Logo.png"
          alt="Northwell Logo"
          width="150"
          height="58"
        />
        <Typography
          sx={{
            ...applyFont(polaris),
            height: '100%',
            alignSelf: 'center',
            fontSize: '22px',
            fontWeight: 720,
            color: '#00359c',
            mx: 1.5,
          }}
        >
          {mobile ? 'AI' : 'Artificial Intelligence'} Hub
        </Typography>
      </Box>
      <Box textAlign="center">
        <Typography
          sx={{
            ...applyFont(copernicus),
            color: '#00359c',
            mb: 4,
          }}
          variant="h4"
        >
          Simplify work, boost productivity
        </Typography>
      </Box>
      {!determiningTeams && (
        <>
          {getSignInForm()}
          {!inTeams && isCypress && <CypressSignIn />}
          {!inTeams && !isCypress && isDev && <DevSignIn />}
        </>
      )}
    </Box>
  );
}

function getSourceParam() {
  if (typeof window === 'undefined') {
    return;
  }
  const url = new URL(window.location.href);
  return url.searchParams.get('source') ?? '';
}
